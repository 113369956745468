body{
    color: black;
}
.container_pol{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
}
p{
    margin: 20px 0;
    font-size: 24px;
}
h2{
    font-size: 30px;
}

@media (max-width:730px){
    p{
        font-size: 20px;
    }
}
@media (max-width:600px){
    a{
        font-size: 18px;
    }
    p{
        font-size: 18px;
    }
}
@media (max-width:430px){
    p{
        font-size: 14px;
    }
}