.chel_modal_main_block {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
    display: flex;
    align-items: end;
    /* background-color: aqua; */
    padding: 30px 50px;
    overflow-y: none;
}
.chel_modal {
    border-radius: 10px;
    width: 500px;
    height: 90vh;
    overflow-y: auto;
    /* height: 80%; */
    position: relative;
    background-color: rgb(234, 234, 234);
}
.chel_modal_block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    z-index: 103;
    /* background-color: violet; */
}
.chel_modal_block > h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    color: white;
}
.blue_fone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    /* z-index: 102; */
    background-color: #136bb2;
}
.closeModal {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 105;
    width: 25px;
    cursor: pointer;
}
/* !togle staaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaart */
.togle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.togle input {
    display: none;
}

.togler {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.togler:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .togler {
    background-color: #2196f3;
}

input:focus + .togler {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .togler:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded togelers */
.togler.round {
    border-radius: 34px;
}

.togler.round:before {
    border-radius: 50%;
}
/* !togle eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeend */

.reset_btn {
    width: 80%;
    height: 35px;
    border-radius: 20px;
    background-color: #fff;
    color: #2196f3;
    font-size: 18px;
    font-weight: 500;
    margin: auto;
    border: none;
    margin-bottom: 30px;
}

.togle_main_block {
    width: 95%;
    margin: auto;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}
.togle_block {
    display: flex;
    /* width: 90%; */
    height: 70px;
    align-items: center;
    margin: auto;
    border-bottom: 1px solid rgba(128, 128, 128, 0.404);
}
.togle_block_text {
    margin-left: 30px;
}
@media (max-width: 600px) {
    .chel_modal_main_block {
        padding: 30px 0px;
    }
    .chel_modal {
        width: 95%;
        margin: auto;
    }
    .chel_modal_block > h2 {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
