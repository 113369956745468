.main_meeting {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    align-items: center;
    /* text-align: center; */
    padding-top: 10%;
    /* align-items: center; */
}
.main_meeting1 input,
.met1 textarea, .main_meeting1 select {
    width: 200%;
    border-radius: 20px;
    border: none;
    height: 50px;
    font-size: 20px;
    padding: 15px;
    margin-top: 16px;
    background-color: #fff;
    border: 1px solid black;
}
.main_meeting1 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.main_meeting p {
    font-size: 25px;
    width: 100%;
    color: black;
}
.main_meeting select{
    width: 100% !important;
    padding: 0 10px !important;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path fill="%23000" d="M0 0l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 95%;
    background-size: 10px 5px;
}
.met1 {
    padding: 10% 0;
    flex-wrap: wrap;
}
.met1 textarea {
    width: 300%;
    height: 150px;
}
.meeting_checkboxes{
    display: flex;
    width: 100%;
    margin-right: 5px;
}
.meeting_checkbox{
    display: flex;
    align-items: center;
    margin:0 5px;
}
.checkbox{
    margin: 0 5px !important;
    width: 30px !important;
}
.btn_block {
    display: grid;
    place-items: center;
}
.next_btn {
    padding: 0 74px 0 60px;
    font-family: Jura-700;
    font-size: 26px;
    line-height: 26px;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    background: linear-gradient(88.32deg, #0679c1 1.42%, #02d6dd 109.3%);
    border-radius: 100px;
    height: 56px;
    border: none;
    color: #fff;
    font-family: sans-serif;
    cursor: pointer;
}
.hidden_block {
    display: none;
}
.thenk {
    width: 90%;
    margin: auto;
    height: 70vh;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.thenk > button {
    width: 220px;
    height: 55px;
    background: linear-gradient(88.32deg, #0679c1 1.42%, #02d6dd 109.3%);
    border-radius: 100px;
    border: none;
    color: white;
    font-size: 22px;
    margin-top: 30px;
}
@media (max-width: 1100px) {
    .main_meeting1 {
        flex-direction: column;
    }
    .main_meeting1 > div {
        width: 100% !important;
    }
    .main_meeting {
        flex-direction: column;
        padding-top: 10px;
    }
    .main_meeting1 input,
    .met1 textarea {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .main_meeting p {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .thenk > h1 {
        font-size: 18px;
    }
    .checkbox{
        margin: 0 3px !important;
        width: 20px !important;
    }
    .meeting_checkbox p {
        font-size: 16px;
    }
}
