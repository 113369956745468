.up_navbar {
    width: 83%;
    height: 40px;
    max-width: 1700px;
    margin: auto;
    display: flex;
    align-items: center;
}
.upNav_email {
    display: flex;
    align-items: center;
    font-size: 12px;
}
.upNav_email > a {
    list-style-type: none;
    text-decoration: none;
    color: white;
    margin-left: 5px;
}
.upNav_number {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 12px;
}
.upNav_number > a {
    list-style-type: none;
    text-decoration: none;
    color: white;
    margin-left: 5px;
}

@media (max-width:580px){
    .up_navbar a {
        font-size: 18px;
    }
}
@media (max-width:450px){
    .up_navbar a {
        font-size: 14px;
    }
}